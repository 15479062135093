<template>
  <ui-component-modal
    :modalTitle="$t('Components.Terms.ModalCreateBookingTerm.Main_Title')"
    modalSize="extra-large"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="save"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <div class="has-margin-bottom-x2">
        <Message :message="$t('Components.Terms.ModalCreateBookingTerm.Message_Info')" />
      </div>

      <ui-base-input-field
        class="has-margin-bottom-x2"
        :label="'Version'"
        type="text"
        v-model="newTerms.Version"
        :placeholder="
          $t('Components.Terms.ModalCreateBookingTerm.Placeholder_Term')
        "
      />

      <div class="divider">
        {{
          $t('Components.Terms.ModalCreateBookingTerm.DividerHeader_Changes')
        }}
      </div>
      <div class="columns is-multiline">
        <div
          v-for="(language, index) in newTerms.Languages"
          :key="'column' + index"
          class="column is-half"
        >
          <p>{{ language.Language }}</p>
          <div class="field">
            <div class="control">
              <textarea
                v-model="language.Changes"
                class="textarea"
                maxlength="6000"
              ></textarea>
            </div>
          </div>
        </div>
      </div>

      <div class="divider">
        {{ $t('Components.Terms.ModalCreateBookingTerm.DividerHeader_Terms') }}
      </div>
      <div class="columns is-multiline">
        <div
          v-for="(language, index) in newTerms.Languages"
          :key="'column' + index"
          class="column is-half"
        >
          <p>{{ language.Language }}</p>
          <div class="field">
            <div class="control">
              <ui-text-editor
                :id="'editor' + index"
                v-model="language.Terms"
              ></ui-text-editor>
            </div>
          </div>
        </div>
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import termsProvider from '@/providers/terms'

const Message = () =>
  import(/* webpackChunkName: "reservations-list" */ '@/components/UI/Message')

export default {
  components: {
    Message,
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },

    channelId: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      newTerms: {
        ChannelId: this.channelId,
        LocationId: this.$route.params.locationId
          ? Number(this.$route.params.locationId)
          : 0,
        Version: '',
        Languages: [],
        CancelRules: [],
      },
    }
  },

  computed: {
    ...mapState('channelStore', ['channel']),
  },

  created() {
    this.setupTermLanguages()
  },

  methods: {
    setupTermLanguages() {
      for (let i = 0; i < this.channel.Languages.length; i++) {
        let language = this.channel.Languages[i]
        this.newTerms.Languages.push({
          Language: language.Language,
          Changes: '',
          Terms: '',
        })
      }
    },

    save() {
      let self = this

      if (!self.isSaving) {
        self.isSaving = true
        termsProvider.methods
          .createBookingTerm(self.newTerms)
          .then((response) => {
            if (response.status === 200) {
              self.isSavingSuccess = true

              let t = setTimeout(() => {
                self.$emit('newTermCreated')
                self.$router.push({
                  name: 'bookingTermDetail',
                  params: { termId: response.data.Id },
                })
                self.onClickCancel()
                clearTimeout(t)
              }, 1500)
            }
          })
          .catch((error) => {
            //console.log(error.response)
            self.isSavingError = true
          })
          .finally(() => {
            self.isSaving = false
          })
      }
    },
  },
}
</script>
